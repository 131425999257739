import React from "react";
import { ContextIsHomeFirstMountValue } from "../../_shared/contextIsHomeFirstMount"
import InitialTransition from "./initialTransition"
import AnimateScrollIntoViewport from "../../_shared/AnimateScrollIntoViewport";

const initialTransitionDuration = 4.5

const SpecialAbsLayer = (props) => {
    const { isHomeFirstMount } = React.useContext(ContextIsHomeFirstMountValue)

    return (<AnimateScrollIntoViewport name="InitialTransition"
        styleToMergeImportant={{ overflow: 'visible' }}
    ><div>
            {isHomeFirstMount && <InitialTransition textTitle="✨Web7.sk✨for🌟you😎" duration={initialTransitionDuration} />}
        </div>
    </AnimateScrollIntoViewport>
    )
}

export default SpecialAbsLayer
